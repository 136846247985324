import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall} from "../components/lib/ad-manage-api-lib";
import {navigate} from "../../.cache/gatsby-browser-entry";
import {getUA} from "react-device-detect";
import {AdAregionSwipetoslide} from "../components/react-slick/ad-aregion-swipetoslide";
import {AdBcdregionSwipetoslide} from "../components/react-slick/ad-bcdregion-swipetoslide";
import cookie from "react-cookies";
import "../styles/bg.css";

export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userId:'',
            gameId:'',
            eventId: '',
            accessToken: '',
            nickname:'',
            createdAt:'',
            expireAt:'',
            expireAt_TTL:'',
            divStyle: '',
            adItem: {
                success: false,
                adId:'',
                eventId:'',
                adItem1: {
                    adItems:[]
                },
                adItem2: {
                    adItems:[]
                },
                adItem3: {
                    adItems:[]
                },
                adItem4: {
                    adItems:[]
                },
                adImage:'',
                adUrl:'',
                adAImage:'',
                adAUrl:'',
                adBImage:'',
                adBUrl:'',
                adCImage:'',
                adCUrl:''
            }
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: ''
        };
        let { eventId, userId ,gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL} = formatQuery(this.props.location.search);
        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        console.log(this.props.location.search);
        console.log("123");
        console.log(eventId);
        // rejectIfAnyNull(this.props.session, this.props.session);
    }

    render() {
        return (
            <Grid textAlign='center' style={{
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{maxWidth: 450}}>
                    <Modal
                        style={{width:'289px',borderRadius:'10px 10px 10px 10px'}}
                        open
                    >
                                <div style={this.state.divStyle}>

                                    <div style={{borderRadius:'10px 10px 0px 0px'}}
                                    >
                                        <iframe src="" style={{width:"100%", height:"100%"}} frameBorder="no" border="0"></iframe>

                                    </div>

                                    <div style={{height:'41px',textAlign:'center',fontSize:'14px',fontWeight:'bold',
                                        paddingTop:'14px',border:'1px solid #D6D6D6',
                                        borderRadius:'0px 0px 10px 10px',
                                        opacity:'1'}}
                                         onClick={() => {
                                             // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                             let issdkorapp = false;
                                             issdkorapp = getUA.includes("JGGSDKWebView");
                                             let platform = cookie.load("_platform");
                                             if ((issdkorapp) || (platform === "h5")){
                                                 AndroidComm.sendSession({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                             }else{
                                                 this.setState({
                                                     divStyle: {
                                                         width: '289px',
                                                         borderRadius: '10px 10px 0px 0px',
                                                         display: 'none'
                                                     }
                                                 });
                                                 console.log("123");
                                             }
                                         }}
                                        >
                                        <span style={{color:'#FF7700'}}>知道了~</span>
                                    </div>
                                </div>
                    </Modal>
                </Grid.Column>
            </Grid>
        );
    }
});