import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ImgVideo, setAdItem} from "../lib/ad-manage-api-lib";
import KaisakuApi from "../../controllers/kaisaku-api";
import {getUA} from "react-device-detect";

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "(255,255,255,0)", right: "0px", zIndex: 1000 }}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "(255,255,255,0)", left: "0px", zIndex: 1000}}
            onClick={onClick}
        />
    );
}

export class AdAregionSwipetoslide extends Component {
    constructor(props, context) {
        super(props, context);
    }

    async showAd(sType, Url, index, imageUrl, click, id) {
        if (Url !== "") {
            let url = Url + "&rr1=download";
            //const uuid = Buffer.from(url).toString('base64');
            const uuid = encodeURIComponent(Buffer.from(url).toString('base64'));
            console.log(uuid);
            let newurl = `/gotoad/?eventId=${this.state.eventId}&gameId=${this.state.gameId}&accessToken=${this.state.accessToken}&userId=${this.state.userId}&nickname=${this.state.nickname}&createdAt=${this.state.createdAt}&expireAt=${this.state.expireAt}&expireAt_TTL=${this.state.expireAt_TTL}&uuid=${uuid}&r1=download`;
            console.log(newurl);
            setAdItem(id, this.state.eventId, sType, index, imageUrl, click);
            let issdkorapp = false;
            issdkorapp = getUA.includes("JGGSDKWebView");
            if (issdkorapp){  
                // if (newurl.indexOf("month-topup") === -1){
                if (url.indexOf("month-topup") !== -1){    
                    const saveurl = window.location.href;
                    const data = await KaisakuApi.setUrlToTemp(saveurl, this.state.accessToken);
                    if (data.status === 200) {
                        url = url.replace(RegExp("@newaccessToken@", "g"), this.state.accessToken);
                        url = url.replace(RegExp("download", "g"), "") + "&code=" + data.data.code;
                        console.log(url);
                        // alert(url);
                        window.location.href = url;
                    }
                }else{
                    window.location.href = newurl;
                }                
            }else{
                url = url.replace(RegExp("@newaccessToken@", "g"), this.state.accessToken);
                window.open(url);
                // window.location.href = newurl;
            }
        }
    }

    render() {
        // console.log("2222");
        // console.log(this.state.adItems);
        const {adItems, eventId, gameId, accessToken, nickname, createdAt, userId,expireAt ,expireAt_TTL} = this.props;
        console.log("eventId = " + eventId);
        this.state = {
            gameId: gameId,
            accessToken: accessToken,
            userId: userId,
            nickname: nickname,
            createdAt: createdAt,
            expireAt: expireAt,
            expireAt_TTL: expireAt_TTL,
            eventId: eventId,
            adItems: [],
        };
        let showed = [];
        this.state.adItems = adItems;
        const settings = {
            dots: false,
            infinite: true,
            arrows: true,
            speed: 1000,
            autoplay: true,
            cssEase: "linear",
            autoplaySpeed: 3000,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: true,
            lazyLoad: "ondemand", // "progressive",
            // pauseOnFocus: true,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            afterChange: function(index) {
                if (adItems[index] !== undefined) {
                    const imageUrl = adItems[index].adImage;
                    const ipos = adItems[index].pos;
                    const id = adItems[index].id;
                    if (!showed.includes(id)){
                        showed.push(id);
                        setAdItem(id, eventId, "A", ipos, imageUrl);
                    }else{
                        console.log("showed = " + showed.indexOf(id));
                    }
                    
                }
            }
        };


        return (
            <div>

            </div>
        );
    }
}

